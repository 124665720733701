@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?hqx0lb');
  src:  url('fonts/icomoon.eot?hqx0lb#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?hqx0lb') format('truetype'),
    url('fonts/icomoon.woff?hqx0lb') format('woff'),
    url('fonts/icomoon.svg?hqx0lb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-share:before {
  content: "\e915";
}
.icon-arrow_back:before {
  content: "\e90e";
}
.icon-arrow_forward:before {
  content: "\e90f";
}
.icon-minus:before {
  content: "\e90c";
}
.icon-plus:before {
  content: "\e90d";
}
.icon-keyboard_arrow_down:before {
  content: "\e901";
}
.icon-keyboard_arrow_left:before {
  content: "\e900";
}
.icon-keyboard_arrow_right:before {
  content: "\e902";
}
.icon-keyboard_arrow_up:before {
  content: "\e903";
}
.icon-filter:before {
  content: "\e914";
}
.icon-renewe:before {
  content: "\e90b";
}
.icon-edit:before {
  content: "\e90a";
}
.icon-upgrade:before {
  content: "\e909";
}
.icon-icon-compare:before {
  content: "\e908";
}
.icon-icon-immobilien:before {
  content: "\e904";
}
.icon-icon-erp:before {
  content: "\e905";
}
.icon-icon-hr:before {
  content: "\e906";
}
.icon-chevron-thin-down:before {
  content: "\e910";
}
.icon-chevron-thin-left:before {
  content: "\e911";
}
.icon-chevron-thin-right:before {
  content: "\e912";
}
.icon-chevron-thin-up:before {
  content: "\e913";
}
.icon-display:before {
  content: "\e956";
}
.icon-pencil:before {
  content: "\e907";
}
.icon-drive:before {
  content: "\e963";
}
.icon-cloud:before {
  content: "\e9c1";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-linux:before {
  content: "\eabd";
}
.icon-apple:before {
  content: "\eabe";
}
.icon-android:before {
  content: "\eac0";
}
.icon-windows:before {
  content: "\eac2";
}
