// GRID

$grid-breakpoints: (
	xs: 0,
	sm: 480px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	xxxl: 1600px,
	xxxxl: 1920px,
    xxxxxl: 2560px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1170px,
	xxl: 1340px,
	xxxl: 1480px,
	xxxxl: 1640px
);

$popover-max-width: 400px;
$popover-font-size: 15px;
$popover-body-color: #20252b;
$popover-body-padding-y: 0;
$popover-body-padding-x: 0;

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
// @import "../node_modules/bootstrap/scss/root";
// @import "../node_modules/bootstrap/scss/utilities";
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/containers";
// @import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/helpers";
// @import "../node_modules/bootstrap/scss/buttons";
// @import "../node_modules/bootstrap/scss/forms";
// @import "../node_modules/bootstrap/scss/utilities/api";
@import "../node_modules/bootstrap/scss/popover";

@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./icons/style.css";
@import "./webfont/style.css";



// FONT-VARIABLES

$font:"Roboto";
$light:300;
$book:400;
$bold:700;
$icon:"icomoon";



// COLOR-VARIABLES

$primary:#c21b17;
$primary-dark:#a72520;
$primary-light:#fdeeeb;
$secondary:#000;
$white:#fff;
$black:#20252b;
$grey:#6f7072;
$darkgrey:#d6d6d6;
$lightgrey:#f6f6f6;
$border:#e5e5e5;

$a-tags:"a, a:active, a:hover, a:visited";
$a-tags-hover:"a:active, a:hover";

@mixin ease {
    transition:all 0.25s ease-in-out;
}



// GENERALS

html,
body,
button,
input,
select,
textarea {
    font-family:$font;
    font-size:16px;
    font-weight:$book;
    color:$black;
}

* {
    outline:0;
}

body {
    background:$lightgrey;
    margin:0;
    padding:0;
    text-rendering:optimizeLegibility;
    overflow-x:hidden;
}

@mixin ease {
    transition:all 0.25s ease-in-out;
}

a:link,
a:visited {
    color:$primary; text-decoration:none; @include ease;
}
a:hover,
a:active,
a:focus {
    color:$black; text-decoration:none; @include ease;
}

h1,h2,h3,h4 {
    font-family:$bold;
}

p {
    font-size:20px;
    line-height:1.5em;
    margin:0 0 25px 0;
    padding:0;
}

b,
strong {
    font-family:$bold; font-weight:normal;
}

::-moz-selection {
    background:$primary;
    color:$white;
    text-shadow:none;
}

::selection {
    background:$primary;
    color:$white;
    text-shadow:none;
}

ul,
ol {
    padding:0 0 0 0;
    margin:0 0 35px 20px;
}

li {
    position:relative;
    padding:0 0 20px 5px;
    margin:0;
}

img {
    width:100%;
    height:auto;
}

legend {
    display:none;
}

.container {
	position:relative;

    @include media-breakpoint-up(xxl) {
        max-width:1364px;
    }

    @include media-breakpoint-up(xxxl) {
        max-width:1364px;
    }
}

select {
    option {
        font-family:Arial;
    }
}

.bg-grey {
    background:$grey;
}

.bg-white {
    background:$white;
}

.modal {
    &.fade {
        transition:opacity .25s linear;
    }

    .modal-header,
    .modal-footer {
        border:0;
    }

    .modal-content {
        border:0;
        border-radius:0;
    }
}



// HEADER

header.top {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    background:$white;
    padding:1rem;
    z-index:3;

    @include media-breakpoint-up(lg) {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        background:rgba(0,0,0,.2);
        padding:0;
    }

    .logo {
        width:10.875rem;

        @include media-breakpoint-up(lg) {
            width:144px;
            height:112px;
            padding:16px 22px;
        }
    }

    .meta {
        position:absolute;
        top:0;
        left:144px;
        background:$primary;
        width:calc(100% - 144px);
        height:40px;

        ul {
            list-style:none;
            margin:0;
            padding:0;

            li {
                margin:0;
                padding:0;
                border-left:1px solid rgba($white,.3);

                #{$a-tags} {
                    font-size:12px; font-weight:$bold; color:$white; text-transform:uppercase; letter-spacing:1px; line-height:20px; padding:6px 23px;

                    svg {
                        margin:-2px 8px 0 0;
                    }
                }

                #{$a-tags-hover} {
                    color:$white;
                }
            }
        }
    }

    .theme-meta-title {
        position:absolute;
        top:40px;
        left:144px;
        width:calc(100% - 144px);
        height:72px;
        padding:0 40px;

        h3 {
            font-size:14px; font-weight:$bold; color:$white; text-transform:uppercase;  margin:0; padding:0;cursor: pointer;
            &:not(:last-child) {
                margin-right: 32px;
            }
            &.product-selection {
                &.active-product a {
                    text-decoration: underline;
                    cursor: default;
                }
                a {
                    color: #ffffff;
                }
                &:not(.active-product):hover {
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}


// Loader

.loader-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}
.loader-bg.initialLoader {
    background: #ffffff;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
}

.loader {
    margin: 2em auto;
    font-size: 10px;
    position: fixed;
    top: 35%;
    left: calc(50% - 25px);
    text-indent: -9999em;
    border-top: 0.5em solid rgba(0, 0, 0, 0.2);
    border-right: 0.5em solid rgba(0, 0, 0, 0.2);
    border-bottom: 0.5em solid rgba(0, 0, 0, 0.2);
    border-left: 0.5em solid $primary-dark;
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    z-index: 9999;
}

@-webkit-keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


// THEME BACKGROUND

.theme-bg {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    overflow:hidden;
    z-index:-1;

    img {
        width:100%;
        height:100%;
        object-fit:cover;
    }

    &:before {
        background-image:linear-gradient(90deg,rgba(0,0,0,.8),transparent);
        bottom:0;
        content:"";
        left: 0;
        position:absolute;
        right:0;
        top:0;
        z-index:2;
    }
}



// THEME TITLE START

.theme-start-title-container {
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    width:100%;

    .theme-title {
        padding-bottom:150px;

        @include media-breakpoint-up(md) {
            padding-bottom:12%;
        }
    }

    h1 {
        font-size:35px; font-weight:300; color:$white; line-height:1.3em; margin:0 auto; padding:0 0 42px 0; text-align:center; max-width:90%;

        @include media-breakpoint-up(md) {
            font-size:60px; max-width:800px;
        }

        @include media-breakpoint-up(lg) {
            font-size:80px;
        }
    }

    .btn {
        max-width:320px; margin:0 auto;
    }
}



// THEME HEADER RED

.theme-header-red {
    min-height:200px;
    padding-bottom:40px;
    background:$primary;

    @include media-breakpoint-up(md) {
        min-height:230px;
        padding-bottom:40px;
    }

    @include media-breakpoint-up(lg) {
        min-height:320px;
        padding-bottom:67px;
    }

    h1 {
        font-size:35px; font-weight:300; color:$white; line-height:1.3em; margin:0 auto; padding:0; text-align:center; max-width:90%;

        @include media-breakpoint-up(md) {
            font-size:46px;
        }

        @include media-breakpoint-up(lg) {
            font-size:56px;
        }
    }
}


.theme-intro {
    padding:46px 0 0 0;

    h2 {
        font-size:35px; font-weight:300; line-height:1.3em; margin:0 auto; padding:0; text-align:center; max-width:90%;

        @include media-breakpoint-up(md) {
            font-size:48px;
        }

        @include media-breakpoint-up(lg) {
            font-size:48px;
        }
    }

    p {
        font-size:20px; font-weight:300; text-align:center; max-width:1144px; margin-left:auto; margin-right:auto; padding:23px 0 0 0;
    }
}



// TABS

.nav {
    border:0;
    margin:70px 0 40px 0;
    padding:0;
    box-shadow:rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 40px 0px;

    .nav-item {
        margin:0;
        padding:0;
    }

    .nav-link {
        border:0;
        border-radius:0;
        background:$white;
        font-size:14px;
        font-weight:$bold;
        color:$black;
        text-transform:uppercase;
        line-height:16px;
        height:64px;
        padding:0;
        display:flex;
        align-items:center;
        justify-content:center;

        &:before {
            position:absolute;
            right:-2px;
            top:50%;
            width:2px;
            height:16px;
            background:#ccc;
            content:"";
            transform:translateY(-50%);
            z-index:2;
        }
    }

    .nav-item {
        &:last-child {
            .nav-link {
                &:before {
                    display:none;
                }
            }
        }
    }

    .nav-link.active,
    .show > .nav-link {
        color:$black;
        background:$primary-light;

        &:after {
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            height:4px;
            background:$primary;
            content:"";
        }

        &:before {
            position:absolute;
            left:0;
            top:50%;
            width:2px;
            height:16px;
            background:$primary-light;
            content:"";
            transform:translateY(-50%);
        }
    }
}


// FORMS

.form-section {
}

.form-container {
    margin:0 0 40px 0;
    background:$white;
    padding:37px 30px;
    box-shadow:rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 40px 0px;

    @include media-breakpoint-up(lg) {
        padding:37px 40px;
    }

    @include media-breakpoint-up(xl) {
        padding:37px 53px;
    }

    @include media-breakpoint-up(xxl) {
        padding:37px 106px;
    }

    h3 {
        font-size:16px; font-weight:700; margin:0; padding:0 0 38px 0;

        span {
            font-weight:400;
        }
    }

    label {
        &.form-label {
            font-size:14px; color:$grey; margin:0; padding:0 0 11px 0; display:block;
        }
    }

    .form-control,
    .form-select {
        border-left:0;
        border-right:0;
        border-top:0;
        border-bottom:1px solid #ccc;
        font-size:20px;
        font-weight:400;
        color:$black;
        border-radius:0;
        box-shadow: none;
        padding:0 0 4px 0;
    }

    textarea.form-control {
        border:2px solid #ccc;
        border-radius:20px;
        min-height:180px;
        padding:20px;
    }

    .form-control:disabled, .form-control[readonly] {
        background:none;
        opacity:.5;
    }

    .form-check {
        .form-check-input {
            width:24px;
            height:24px;
            border-color:#ccc;
            box-shadow:none;

            &:checked {
                background:$white;
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23c21b17'/%3e%3c/svg%3e");
            }
        }

        label.form-check-label {
            font-size:20px; font-weight:400; padding:2px 0 0 8px;
        }
    }

    span.year {
        font-size:20px; margin:0; padding:0 0 5px 0; display:block;
    }

    .mb-4 {
        position:relative;
        margin-bottom:32px !important;
    }
}

.custom-file-uploader {
    position: relative;

    input[type='file'] {
        display:block;
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        z-index:5;
        width:100%;
        height:100%;
        opacity:0;
        cursor:default;
    }

    .input-group-btn {
        span {
            display:none;
        }
    }
}

.input-group {
    margin:13px 0 0 0;
}

.form-section-controls {
    z-index: 100;
    position:sticky;
    bottom:0;
    left:0;
    width:100%;
    padding:40px 0;
    background:$white;
    box-shadow:rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 40px 0px;

    @include media-breakpoint-up(lg) {
        padding:40px 106px;
    }

    .btn {
        margin:0 0 15px 0;
        width:100%;

        @include media-breakpoint-up(lg) {
            margin:0;
        }
    }
}



// MODAL

.modal {
    .form-container {
        box-shadow:none;
        padding:0;
   }
}


// ACCORDION

.accordion {
    .accordion-item {
        border:0;
    }

    .accordion-item:first-of-type .accordion-button {
        border:0;
        border-radius:0;
        box-shadow:none;
    }

    .accordion-button {
        background:$lightgrey;
        padding-right:60px;

        &:focus {
            box-shadow:none;
        }

    }



    .accordion-button:after {
        position:absolute;
        top:50%;
        right:30px;
        margin-top:-3px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.414' height='10.121'%3E%3Cpath data-name='Pfad 45' d='M15.999 10.121 8.707 2.828l-7.293 7.293L0 8.707 8.707 0l8.707 8.707Z' fill='%2320252b'/%3E%3C/svg%3E");        transform:rotate(0deg);
    }

    .accordion-button.collapsed:after {
        position:absolute;
        top:50%;
        right:30px;
        margin-top:-3px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.414' height='10.121'%3E%3Cpath data-name='Pfad 46' d='m1.415 0 7.292 7.293L16 0l1.414 1.414-8.707 8.707L0 1.414Z' fill='%2320252b'/%3E%3C/svg%3E");        transform:rotate(0deg);
        transform:rotate(0deg);
    }

    .accordion-button {
        font-size:20px; font-weight:300; color:$black;

        @include media-breakpoint-up(xl) {
            font-size:25px;
        }

        @include media-breakpoint-up(xxl) {
            font-size:32px;
        }
    }

    .accordion-body {
        padding-left:0;
        padding-right:0;
    }
}

table {
    font-size:14px;

    tr {
        th {
            text-align:right;
            padding:27px 15px 24px 15px !important;

            @include media-breakpoint-up(xl) {
                padding:27px 25px 24px 25px !important;
            }
        }

        td {
            font-weight:700; text-align:right; padding:27px 15px 26px 15px !important;

            @include media-breakpoint-up(xl) {
                padding:27px 25px 24px 25px !important;
            }

        }

        th[scope="row"] {
            font-weight:400; text-align:left;

            @include media-breakpoint-up(md) {
                width:60%;
            }

            @include media-breakpoint-up(xl) {
                width:70%;
            }
        }
    }
}


// RANGE SLIDER
$shade-10: $primary;
$shade-1: #d7dcdf;
$shade-0: #fff;
$teal: $primary;
// You may be interested in editing these values to shrink the slider element
.range-slider {
    background:$lightgrey;
  }

  // Range Slider
  $range-width: 100% !default;

  $range-handle-color: $shade-10 !default;
  $range-handle-color-hover: $teal !default;
  $range-handle-size: 20px !default;

  $range-track-color: $darkgrey !default;
  $range-track-height: 10px !default;

  $range-label-color: $shade-10 !default;
  $range-label-width: 60px !default;

  .range-slider {
    width: $range-width;
    padding:30px 30px 20px 30px;

    @include media-breakpoint-up(lg) {
        padding:40px 40px 30px 40px;
    }
  }

  .range-slider-legend {
    padding:10px 0 0 0;

    .text-end {
        margin-right:73px;
    }
  }

  .range-slider__range {
    -webkit-appearance: none;
    width: calc(100% - (#{$range-label-width + 13px}));
    height: $range-track-height;
    border-radius: 0;
    background: $darkgrey;
    outline: none;
    padding: 0;
    margin: 0;

      &~ * {
          user-select: none;
      }

    // Range Handle
    &::-webkit-slider-thumb {
      appearance: none;
      width: $range-handle-size;
      height: $range-handle-size;
      border-radius: 50%;
      background: $range-handle-color;
      cursor: pointer;
      transition: background 0.15s ease-in-out;

      &:hover {
        background: $range-handle-color-hover;
      }
    }

    &:active::-webkit-slider-thumb {
      background: $range-handle-color-hover;
    }

    &::-moz-range-thumb {
      width: $range-handle-size;
      height: $range-handle-size;
      border: 0;
      border-radius: 50%;
      background: $range-handle-color;
      cursor: pointer;
      transition: background 0.15s ease-in-out;

      &:hover {
        background: $range-handle-color-hover;
      }
    }

    &:active::-moz-range-thumb {
      background: $range-handle-color-hover;
    }

    // Focus state
    &:focus {
      &::-webkit-slider-thumb {
        box-shadow: 0 0 0 3px $shade-0, 0 0 0 6px $teal;
      }
    }
    &:focus {
      &::-moz-range-thumb {
        box-shadow: 0 0 0 3px $shade-0, 0 0 0 6px $teal;
      }
    }

  }

  // Range Label
  .range-slider__value {
    display: inline-block;
    position: relative;
    width: $range-label-width;
    color: $shade-0;
    line-height: 20px;
    text-align: center;
    border-radius: 3px;
    background: $range-label-color;
    padding: 5px 10px;
    margin-left: 8px;

    &:after {
      position: absolute;
      top: 8px;
      left: -7px;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 7px solid $range-label-color;
      border-bottom: 7px solid transparent;
      content: "";
    }
  }

  // Firefox Overrides
  ::-moz-range-track {
    background: $range-track-color;
    border: 0;
  }

  input::-moz-focus-inner,
  input::-moz-focus-outer {
    border: 0;
  }



// HELPERS

.btn-primary {
    --bs-btn-hover-bg: #c21b17;
    --bs-btn-hover-border-color: #c21b17;
}

.btn-secondary {
    --bs-btn-hover-border-color: #c21b17;
}

a.btn,
.btn {
    color:$white;
    background:$primary;
    border:2px solid $primary;
    font-size:15px;
    font-weight:700;
    letter-spacing:1px;
    line-height:20px;
    text-transform: uppercase;
    padding-left:1rem;
    padding-right:1rem;
    padding-top:2px;
    padding-bottom:0;
    border-radius:4px;
    height:48px;
    align-items:center;
    justify-content:center;
    display:flex;
    box-shadow:none;
    --bs-btn-active-border-color: #c21b17;

    @include media-breakpoint-up(xl) {
        padding-left:2.5rem;
        padding-right:2.5rem;
    }

    &:focus,
    &:active {
        box-shadow:none !important;
    }

    &:hover {
        background:$primary-dark;
        border-color:$primary-dark;
    }

    &.btn-secondary {
        color:$primary;
        background:$white;

        &:hover {
            color:$white;
            background:$primary-dark;
        }
    }

    &.btn-modal {
        background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='40'%3E%3Cg data-name='Gruppe 21'%3E%3Cg data-name='Gruppe 20'%3E%3Cpath data-name='Rechteck 165' fill='%23fff' d='M0 39h60v1H0z'/%3E%3C/g%3E%3Cg data-name='Gruppe 19' transform='translate(-1081 -619)'%3E%3Cg data-name='Ellipse 18' transform='translate(1101 619)' fill='%23fff' stroke='%23b62018'%3E%3Ccircle cx='20' cy='20' r='20' stroke='none'/%3E%3Ccircle cx='20' cy='20' r='19.5' fill='none'/%3E%3C/g%3E%3Ccircle data-name='Ellipse 19' cx='1' cy='1' r='1' transform='translate(1114 644)' fill='%23b62018'/%3E%3Ccircle data-name='Ellipse 20' cx='1' cy='1' r='1' transform='translate(1120 644)' fill='%23b62018'/%3E%3Ccircle data-name='Ellipse 21' cx='1' cy='1' r='1' transform='translate(1126 644)' fill='%23b62018'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size:60px 40px;
        background-repeat:no-repeat;
        border:0;
        background-color:transparent;
        padding:0;
        width:60px;
        height:40px;
        position:absolute;
        bottom:0;
        right:0;

        span {
            display:none;
        }
    }
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active, .btn-primary:active,
.show > .btn-primary.dropdown-toggle {
    color:$white;
    background:$primary-dark;
}

.form-control::placeholder {
    color:$black;
    opacity:1;
}

.form-control::-ms-input-placeholder {
    color:$black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance:none;
    margin:0;
}

input[type=number] {
    -moz-appearance:textfield;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display:none;
    -webkit-appearance:none;
}

[data-main-navigation], input[type="radio"] {
    cursor: pointer;
}

input:disabled {
    cursor: not-allowed;
}
input[type="text"], input[type="number"], input[type="date"], input[type="email"], input[type="file"], input[type="url"] {
    &:focus {
        border-bottom-color: #c21b17;
    }
}

.form-check .form-check-label {
    margin-bottom: 1px;
}

.invalid-error {
    padding: 10px 8px;
    background-color: rgba(233,87,61,.1);
    color: #e9573d;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .25px;
}

.modal-error-title {
    color: #e9573d;
}

.svg-tooltip {
    * {
        font-family: $font;
    }
    .svg-tooltip-row {
        display: flex;
        justify-content: space-between;
        padding: 8px 12px;
        letter-spacing: .25px;
        &:nth-child(even) {
            background-color: #efefef;
        }
        & > div {
            &:first-child {
                margin-right: 20px;
            }
            &:last-child {
                font-weight: 500;
            }
            padding: 0;
        }
    }
}

circle.has-popover-bound:hover,
circle.has-popover-bound[aria-describedby^="popover"] {
    fill: #c21b17;
}
#hochrechnung-graph {min-height: 400px;}
.graph-legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 22px;
    & > div {
        flex: 0 0 auto;
        position: relative;
        padding-left: 24px;
        &:not(:last-child) {
            margin-right: 32px;
        }
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 16px;
            height: 16px;
            //border-radius: 50%;
        }
        &.color-y1:before {
            background: #ffa740;
        }
        &.color-y2:before {
            background: #2277dd;
        }
    }
}